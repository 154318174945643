import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { map, flatMap, catchError } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

declare var configGU: any;

export interface Credentials { Username: string, Password: string, Target: string }

@Injectable()
export class StateService {
    private _token: string;
    loggedIn = false;

    get token() { return this._token; }
    set token(value: string) { this._token = value; }
}

@Injectable()
export class DataService {
    baseURL: string;
    public authURL: string;
    public loginURL: string;
    public userInfo: any;
    claims: { [index: string]: any };
    public appID = 'Tickets';
    filterModel: any;

    constructor(private http: HttpClient, private datePipe: DatePipe) {
        var pos = configGU.WebServiceUrl.lastIndexOf('/api');
        if (pos > 0)
            this.baseURL = configGU.WebServiceUrl.substr(0, pos + 1);
        else
            this.baseURL = configGU.WebServiceUrl;

        this.userInfo = {};  //null;

        this.authURL = configGU.AuthUrl;
        this.loginURL = configGU.LoginUrl; 
    }

    get authToken() { return this.claims['refresh_token']; }

    get isAuthenticated() { return this.userInfo && this.userInfo.A_ID > 0; }

    get hasPermission() { return this.isAuthenticated && this.userInfo.Permissions.some(x => x.S_SHORTP === this.appID)}

    isInRole(role: string): boolean {
        return this.userInfo.Permissions.some(x => x.S_SHORTP === this.appID && x.P_CODE == role);
    };

    getTickets(request): Observable<any> {
      return this.http.post(this.baseURL + 'api/Tickets', request)
            .pipe(catchError((error) => { return this.handleError(error); }));
    }

    saveTicket(ticket: any): Observable<any> {
      return this.http.post(this.baseURL + 'api/Ticket', ticket)
            .pipe(catchError((error) => { return this.handleError(error); }));
    }

  getFilters(fil?: string): Observable<any> {
      return this.http.get(this.baseURL + 'api/Filters' + (fil ? '/'+fil : ''))
        .pipe(catchError((error) => { return this.handleError(error); }));
  }

    getTicket(ctrl: number): Observable<any> {
        return this.http.get(this.baseURL + 'api/Ticket/' + ctrl)
            .pipe(catchError((error) => { return this.handleError(error); }));
    }

    getDocument(ctrln: number, line: number, isProg? : boolean): any {
        let headers = new HttpHeaders();
        return this.http.get(this.baseURL + `api/Attachment/${ctrln}/${line}${isProg ? '?isProg=1' : ''}`, { headers: headers, responseType: 'blob' })
            .pipe(catchError((error) => { return this.handleError(error); }));
    }

    addUpload(ticket: any): Observable<any> {
        return this.http.post(this.baseURL + 'api/Attachment', ticket)
            .pipe(catchError((error) => { return this.handleError(error); }));
    }

    addProgUpload(data: any): Observable<any> {
        return this.http.post(this.baseURL + 'api/AttachmentProg', data)
            .pipe(catchError((error) => { return this.handleError(error); }));
    }

    removeUpload(ctrln: number, line: number, isProg? : boolean): Observable<any> {
        return this.http.delete(this.baseURL + `api/Attachment/${ctrln}/${line}${isProg ? '?isProg=1' : ''}`)
            .pipe(catchError((error) => { return this.handleError(error); }));
    }

    saveTicketDetails(ticket: any): Observable<any> {
        return this.http.post(this.baseURL + 'api/TicketDetails', ticket)
            .pipe(catchError((error) => { return this.handleError(error); }));
    }

    sendEmail(args: any): Observable<any> {
        return this.http.post(this.baseURL + 'api/SendEmail', args)
            .pipe(catchError((error) => { return this.handleError(error); }));
    }

    sendCarrierEmail(args: any): Observable<any> {
      return this.http.post(this.baseURL + 'Render/EmailCarrier', args)
        .pipe(catchError((error) => { return this.handleError(error); }));
    }

    getUsers(): Observable<any> {
        let hdr = new HttpHeaders({ 'Authorization': `Bearer ${this.userInfo.ID}` });
        //return this.http.post(this.authURL + 'api/UpdateUser', obj, { headers: hdr })
        //    .pipe(catchError((error) => { return this.handleError(error); }));
        return this.http.get(this.authURL + `api/Users/0/${this.appID}`, { headers: hdr, withCredentials: true })
            .pipe(catchError((error) => { return this.handleError(error); }));
    }

    getPermissions(): Observable<any> {
        let hdr = new HttpHeaders({ 'Authorization': `Bearer ${this.userInfo.ID}` });
        return this.http.get(this.authURL + `api/AppPermissions/${this.appID}`, { headers: hdr, withCredentials: true })
            .pipe(catchError((error) => { return this.handleError(error); }));
    }

    login(credentials: any): Observable<any> {
        credentials["target"] = this.appID;

        return this.http.post(this.authURL + 'api/Portal/Login', credentials, { withCredentials: true })
            .pipe(catchError((error) => { return this.handleError(error); }));
    }

    logout(): Observable<any> {
        return this.http.post(this.authURL + 'api/Portal/Logout', {}, { withCredentials: true })
            .pipe(catchError((error) => { return this.handleError(error); }));
    }

    formatAS400Date(strdate, strtime) {
        if ((!strdate || strdate === 0) && (!strtime || strtime === 0))
            return null;
        var date;
        let str = (!strdate || strdate === 0) ? '19000101' : strdate.toString();
        if (strtime) {
            let strt = ("000000" + strtime.toString()).slice(-6);
            date = new Date(parseInt(str.substring(0, 4)), parseInt(str.substring(4, 6)) - 1, parseInt(str.substring(6, 8)), parseInt(strt.substring(0, 2)), parseInt(strt.substring(2, 4)));
        }
        else {
            date = new Date(str.substring(0, 4), parseInt(str.substring(4, 6)) - 1, str.substring(6, 8));
        }
        return date;
    }

    processError(err: any): string {
        var errorMessage: string;
        if (err.ExceptionMessage) {
            errorMessage = err.ExceptionMessage;
            var ex = err.InnerException;
            while (ex) {
                errorMessage += (" => " + ex.ExceptionMessage);
                ex = ex.InnerException;
            }
        }
        else if (err.Message)
            errorMessage = err.Message;
        else if (err.message)
            errorMessage = err.message;
        else
            errorMessage = err;

        return errorMessage;
    }

    public handleError(error: any) {
        if (error.status == 500) {
            var jerr = error.error;
            //if (jerr && jerr.ExceptionMessage === 'UnauthorizedException')
            //    this.messengerService.statusChanged('UnauthorizedException');
            //else
            return observableThrowError(jerr || 'Server error');
        }
        else if (error.status == 200 && error.error) {
            var jerr = error.error;
            return observableThrowError(jerr);
        }
        else
            return observableThrowError({ Message: error.status + ' ' + error.statusText });
    }
}

import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { AllModules, GridOptions } from '@ag-grid-enterprise/all-modules';
import { AllCommunityModules, ClientSideRowModelModule } from '@ag-grid-community/all-modules';
import { zip, Subscription } from 'rxjs';

import { DataService } from '../data.service';
import { CustomDateComponent } from '../custom-date.component';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
    errorMessage: string;
    printing: boolean;
    selTickets: any[];
    users: any[];
  //private sub: any;

    rowData: any[];
    private frameworkComponents;
  private gridOptions: GridOptions;
  private columnDefs: any[];
  private gridApi;
    private gridColumnApi;
    filterModel: any;

  constructor(private dataService: DataService, private datePipe: DatePipe, public router: Router, private route: ActivatedRoute) { 
        this.printing = false;
        this.selTickets = [];

        this.gridOptions = <GridOptions>{
            rowModelType: 'serverSide',
            cacheBlockSize: 50,
            //paginationPageSize: 20,
            pagination: false, 
      //suppressRowClickSelection: true,
      defaultColDef: {
        sortable: true,
        resizable: true
      },
      };

      this.frameworkComponents = { agDateInput: CustomDateComponent };

    this.columnDefs = [
      {
            headerName: "ID", field: "REQNO", width: 95, suppressSizeToFit: true, filter: 'agNumberColumnFilter', filterParams: { filterOptions: ["equals"] }
      },
      {
          headerName: "Status", field: "Status", suppressSizeToFit: true, sortable: false,
        //valueFormatter: (param) => { return this.convertStatus(param.value); },
          filter: 'agSetColumnFilter',
          filterParams: {
              values: ['New', 'Approved', 'Assigned', 'Closed', 'Cancelled']
          }
      },
      {
          headerName: "Opened", field: "REQDAT", suppressSizeToFit: true, sort: 'desc', minWidth: 190,
          valueFormatter: (param) => { return this.datePipe.transform(this.dataService.formatAS400Date(param.value, 0), 'mediumDate'); },
          comparator: function (valueA, valueB, nodeA, nodeB, isInverted) {
              if (!valueA && !valueB)
                  return 0;
              else if (!valueA)
                  return -1;
              else if (!valueB)
                  return 1;
              else
                  return (new Date(valueA)).getTime() - (new Date(valueB)).getTime();
          },
        filter: 'agDateColumnFilter', filterParams: { filterOptions: ["startsWith"] }
      },
      {
        headerName: "Submitted By", field: "REQBY",
          valueGetter: (param) => { return !param.data ? '' : param.data.REQFNAM !== '' && param.data.REQLNAM !== '' ? `${param.data.REQFNAM} ${param.data.REQLNAM}` : param.data.REQBY; },
          filter: 'agSetColumnFilter', filterParams: { values: this.getFilerValues.bind(this) }
      },
      {
          headerName: "Address", field: "ADDRESS", maxWidth: 135,
          valueGetter: (param) => { return !param.data ? '' : param.data.ADDRESS + (param.data.UNIT !== '' ? `Unit ${param.data.UNIT}` : ''); },
          filter: 'agTextColumnFilter', filterParams: { filterOptions: ["contains"] }
      },
      {
          headerName: "Phone / Ext", field: "PHONE", maxWidth: 135,
          valueGetter: (param) => { return !param.data ? '' : param.data.PHONE + (param.data.PHONEXT != '' ? ` Ext. ${param.data.PHONEXT}` : ''); },
          filter: 'agTextColumnFilter', filterParams: { filterOptions: ["contains"] }
      },
      {
          headerName: "Company", field: "REQCOMP", maxWidth: 135,
          filter: 'agSetColumnFilter', filterParams: { values: this.getFilerValues.bind(this) }
      },
        {
            headerName: "Assigned To", field: "ASGNTO", sortable: false,
            valueFormatter: (param) => {
                var ua = this.users.find(x => x.A_ID == param.value);
                return ua ? ua.USERNAME : param.value;
            },
            filter: 'agSetColumnFilter', filterParams: { values: [], suppressSorting: true }
        },
        {
            headerName: "Priority", field: "PRIRTY", 
            filter: 'agSetColumnFilter',
            filterParams: {
                values: ['A', 'B', 'C']
            }
        },
      {
          headerName: "Type", field: "REQTYPE", sortable: false,
          valueFormatter: (param) => { return this.convertType(param.value); },
          filter: 'agSetColumnFilter', filterParams: { values: ['Hardware', 'AS400 Technical', 'Email', 'Programming AS400/PC', 'Phone', 'SAS', 'Other'] }
      },
        {
            headerName: "Description", field: "Description", cellClass: "cell-wrap-text", autoHeight: true, suppressSizeToFit: true, width: 500, sortable: false,
            filter: 'agTextColumnFilter', filterParams: { filterOptions: ["contains"] }
        },
        ];
    //this.getData();
    }

    ngOnInit() {
        zip(this.dataService.getUsers(), this.dataService.getPermissions(), this.dataService.getFilters('ASGNTO')).subscribe((ui: any) => {
            this.users = ui[0];
            this.users.forEach(u => {
                u.Permissions = ui[1].filter(x => x.A_ID == u.A_ID);
            });

            //var ass = ui[2].ASGNTO.map(x => {
            //    var ua = this.users.find(u => u.A_ID == x);
            //    return { name: ua ? ua.USERNAME : '', id: x };
            //});
            //var role = this.ticket.REQTYPE == '04' ? 'PROG' : 'OPER';
            var ass = this.users.filter(x => x.Permissions.some(p => p.P_CODE == 'PROG' || p.P_CODE == 'OPER')).map(x => { return { name: x.USERNAME, id: x.A_ID } });

            ass.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)); 
            this.columnDefs.find(x => x.field == 'ASGNTO').filterParams.values = ass.map(x => x.id); //ui[2]['ASGNTO'];
        },
            (err: any) => {
                this.errorMessage = this.dataService.processError(err);
            });
    }

  //ngOnDestroy() {
  //  this.sub.unsubscribe();
  //}

    getFilerValues(params) {
        var f = params.colDef.field;
        this.dataService.getFilters(f).subscribe((res: any) => {
            params.success(res[f]);
        });
    }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

      this.onRowDataChanged();

      if (this.dataService.filterModel) {
          this.filterModel = this.dataService.filterModel;
          this.dataService.filterModel = null;
      }

      if (!this.filterModel)
          this.newTickets();
      else {
          this.gridApi.setFilterModel(this.filterModel);
          this.gridApi.onFilterChanged();
      }

      params.api.setServerSideDatasource({
          getRows: this.getData.bind(this)
      });
    //let newPageSize = this.rowData.length < 15 ? this.rowData.length : 15;
    //this.gridApi.paginationSetPageSize(newPageSize);

    //params.api.sizeColumnsToFit();
    //this.autoSizeAll(this.gridColumnApi);
      //params.columnApi.autoSizeColumns([params.columnApi.getColumn("REQNO"), params.columnApi.getColumn("Status"), params.columnApi.getColumn("REQDAT"), params.columnApi.getColumn("REQCOMP"), params.columnApi.getColumn("REQTYPE")]);
  }

  autoSizeAll(colapi: any) {
    var allColumnIds = [];
      colapi.getAllColumns().forEach(column => {
          if (column.colId != 'Description' && column.colId != 'REQNO')
            allColumnIds.push(column.colId);
    });
    colapi.autoSizeColumns(allColumnIds);
  }

    onRowDataChanged() {
        this.autoSizeAll(this.gridColumnApi);
        this.gridApi.sizeColumnsToFit();
    }

    getData(params) {
        this.dataService.getTickets(params.request).subscribe((res: any[]) => {
            this.errorMessage = '';
          let rowData = res.map(x => {
              let r = Object.assign({}, x);

              for (var p in x)
                  if (typeof x[p] == 'string') r[p] = r[p].trim();
              r["Status"] = this.convertStatus(x.TEMP);
              //r["SubDate"] = this.datePipe.transform(this.dataService.formatAS400Date(x.REQDAT, 0), 'mediumDate');

              if (!r.ASGNTO)
                  r.ASGNTO = '';

              return r;
            });

            let numRec = null;
            if (rowData.length > 0) {
                numRec = rowData[0].GridCount;
                rowData.splice(0, 1);
            }
            params.successCallback(rowData, numRec);
            setTimeout(() => this.onRowDataChanged());
    },
      (err: any) => {
          this.errorMessage = this.dataService.processError(err);
          params.failCallback();
      });
  }

    dblClick1(evt) {
        this.dataService.filterModel = this.gridApi.getFilterModel();
        this.router.navigate(['admin', (evt.data.REQNO * 3 + 7).toString()]);
  }

  newClaim() {
    this.router.navigate(['home']);
  }

    newTickets() {
        if (this.dataService.isInRole('SAS'))
            return;
        var filterComponent = this.gridApi.getFilterInstance("Status");
        filterComponent.selectNothing();
        //filterComponent.selectValue('New');
        var status = this.dataService.isInRole('APPROV') || this.dataService.isInRole('ADMIN') ? "New" : "Assigned";
        this.setFilterValue(filterComponent, status);
        filterComponent.applyModel();

        if (!(this.dataService.isInRole('APPROV') || this.dataService.isInRole('ADMIN'))) {
            filterComponent = this.gridApi.getFilterInstance("ASGNTO");
            filterComponent.selectNothing();
            this.setFilterValue(filterComponent, this.dataService.userInfo.A_ID);
            filterComponent.applyModel();
        }

        this.gridApi.onFilterChanged();
    }

    private setFilterValue(filterComponent: any, val: string) {
        var n = filterComponent.getUniqueValueCount();
        for (var i = 0; i < n; i++) {
            var v = filterComponent.getUniqueValue(i);
            if (v == val) {
                filterComponent.selectValue(val);
                return;
            }
        }
    }

    doPrint() {
        this.selTickets = this.gridApi.getSelectedRows();
        if (this.selTickets.length == 0)
            return;
        this.filterModel = this.gridApi.getFilterModel();
        this.printing = true;
        setTimeout(() => {
            window.print();
            this.printing = false;
        });
    }

    logout() {
        this.dataService.userInfo = null;
        this.dataService.logout().subscribe((res: any[]) => {
            var currentAbsoluteUrl = window.location.href;
            var currentRelativeUrl = this.router.url;
            var baseUrl = currentAbsoluteUrl.substring(0, currentAbsoluteUrl.length - currentRelativeUrl.length);

            let absoluteUrl = baseUrl + this.router.createUrlTree([this.router.url]);
            window.location.href = 'https://www.globalfurnituregroupapps.com/AppPortal';  // this.dataService.loginURL + '?redirect=' + absoluteUrl;
        });
    }

  convertType(type) {
    switch (type) {
      case '01': return 'Hardware';
      case '02': return 'AS400 Technical';
      case '03': return 'Email';
      case '04': return 'Programming AS400/PC';
      case '05': return 'Phone';
      case '06': return 'SAS';
      case '09': return 'Other';
      default: return type;
    }
  }

  convertStatus(code) {
    switch (code) {
      case 'N': return 'New';
      case 'A': return 'Approved';
      case 'P': return 'Assigned';
      case 'F': return 'Closed';
      case 'C': return 'Cancelled';
      default: return '';
    }
  }
}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LicenseManager } from "@ag-grid-enterprise/core";
import { ModuleRegistry, AllModules } from '@ag-grid-enterprise/all-modules';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

LicenseManager.setLicenseKey("Global_Upholstery_Co_Inc__MultiApp_1Devs20_December_2019__MTU3NjgwMDAwMDAwMA==fb48cec5fcb98648fa27f3287188fad4");
ModuleRegistry.registerModules(AllModules);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

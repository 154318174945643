import { Component, OnInit, EventEmitter, Input, Output, OnChanges, SimpleChange, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FileUploader, FileItem, FileUploaderOptions } from 'ng2-file-upload';

import { DataService } from '../data.service';
import { ConfirmService } from '../confirmation-dialog/confirm.service';

interface IDictionary {
    [index: string]: any;
}

@Component({
  selector: 'app-doc-upload',
  templateUrl: './doc-upload.component.html',
  styleUrls: ['./doc-upload.component.css']
})
export class DocUploadComponent implements OnInit, OnChanges {
    public uploader: FileUploader;
    public hasBaseDropZoneOver: boolean = false;
    public hasAnotherDropZoneOver: boolean = false;
    docType: any;
    description: string;
    @ViewChild('fileinput', { static: false }) input: ElementRef;

    @Output() onCompleted: EventEmitter<any> = new EventEmitter<any>();
    @Output() onRemoved: EventEmitter<string> = new EventEmitter<string>();
    @Output() onView: EventEmitter<string> = new EventEmitter<string>();

    @Input() noView: boolean;
    @Input() disabled: boolean;
    @Input() showConfirm: boolean = true;
    @Input() uploads: any[];

    constructor(private dataService: DataService, private _confirm: ConfirmService) {
        this.docType = null;
        this.disabled = false;
        this.noView = false;
        this.description = '';
    }

  ngOnInit() {
        this.uploader = new FileUploader({ url: this.dataService.baseURL + 'api/DocUpload' });

      this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
          if (fileItem.file.size > 5120000) {
              fileItem.isSuccess = false;
              fileItem.isError = true;
              this.onCompleted.emit({ description: this.description, file: fileItem, message: 'File size cannot exceed 5MB' });
          }
          else {
              fileItem.withCredentials = false;
              this.docType = { description: this.description, file: fileItem };
              fileItem.isReady = true;
              fileItem.upload();
              this.input.nativeElement.value = '';
          }
        };
        this.uploader.onCompleteItem = (item: FileItem, response: any, status: any, headers: any) => {
            if (response === '')
                return;
            var responseObj = JSON.parse(response);
            this.docType["message"] = responseObj.Message;
            if (!responseObj.Success) {
                item.isSuccess = false;
                item.isError = true;
            }
            else
                item.isSuccess = true;

            this.onCompleted.emit(this.docType);
            this.docType.selected = false;

            this.docType = null;
        };
    }

    ngOnChanges(changes: SimpleChanges) {
        const prop: SimpleChange = changes['uploads'];
        if (prop && prop.currentValue) {
            for (var u of prop.currentValue) {
            }
        }
    }

    get itemList() {
        return this.uploads;
    }

    viewFile(item: any) {
        this.onView.emit(item.key);
    }

    removeFile(item: any) {
      this._confirm.activate('This will delete the uploaded document.', 'Warning', 'OK', 'Cancel').
        then(res => {
          if (res) {
            this.onRemoved.emit(item.key);
          }
        });
    }

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    public fileOverAnother(e: any): void {
        this.hasAnotherDropZoneOver = e;
    }
}

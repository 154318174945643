import { Component, ViewChild, OnInit } from '@angular/core';

@Component({
    selector: 'not-authorized',
    template: `
      <div><span style="margin-left:20px;color:red;font-size:18px">You are not authorized to use this application</span></div>
        `,
    styles: [`
        .topOffset { margin-top: 10px }
        .appTop {
            text-align:center; 
            height: 80px;
            margin-top: 5px;
            margin-bottom: 20px;
        }
        .title {
            height: 80px;
            float: left;
            margin-left: 50px;
        }
         h2 { line-height: 80px; }
    `]
})
export class NotAuthorizedComponent implements OnInit {

    ngOnInit(): void {
    }

    constructor() { }
}

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { zip } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { DataService } from '../data.service';
import { ModalDetailsComponent } from './modal-details.component';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  sub: any;
  errorMessage: string;
  loading = false;
  reqno: number;
    @Input() ticket: any;
    uploads: any[];
    origStatus: string;
    @Input() users: any[];
    printing: boolean;
    settingsSess: any;
    progList: any[] = [];
    progSelected: any[];
    bsModalRef: BsModalRef;

    constructor(private dataService: DataService, private datePipe: DatePipe, public router: Router, private route: ActivatedRoute, private modalService: BsModalService) { }

  ngOnInit() {
      this.uploads = [];

      this.settingsSess = {
          badgeShowLimit: 4, enableCheckAll: false, selectAllText: 'All', unSelectAllText: 'Unselect All', text: 'Additional programmers'
      };

      this.progSelected = [];

      if (this.ticket) {
          this.printing = true;
          this.reqno = this.ticket.REQNO;
          this.processTicket();
          this.uploads = [];
          for (var att of this.ticket.Attachments)
              this.uploads.push({ key: att.REQLIN, file: { isSuccess: true, file: { name: att.REQATT } }, description: att.REQLNK, selected: false, db: true });
      }
      else {
          this.printing = false;
          this.sub = this.route.params.subscribe(params => {
              if (!params['ctrl'] && !this.ticket)
                  return;

              if (params['ctrl'])
                  this.reqno = (Number(params['ctrl']) - 7) / 3;

              this.dataService.getTicket(this.reqno).subscribe((ui: any) => {
                  var r = ui;
                  this.ticket = r;  //Object.assign({ OpenDate: '' }, r);
                  this.uploads = [];
                  for (var att of r.Attachments)
                      this.uploads.push({ key: att.REQLIN, file: { isSuccess: true, file: { name: att.REQATT } }, description: att.REQLNK, selected: false, db: true });

                  if (this.dataService.hasPermission) {
                      zip(this.dataService.getUsers(), this.dataService.getPermissions()).subscribe((ui: any) => {
                          this.users = ui[0];
                          this.users.forEach(u => {
                              u.Permissions = ui[1].filter(x => x.A_ID == u.A_ID);
                          });

                          this.processTicket();
                      },
                          (err: any) => {
                              this.errorMessage = this.dataService.processError(err);
                          });
                  }
                  else {
                      this.users = [];
                      this.processTicket();
                  }
              },
                  (err: any) => {
                      this.errorMessage = this.dataService.processError(err);
                  });
          });
      }
  }

    ngOnDestroy() {
        if (this.sub)
            this.sub.unsubscribe();
  }

  goList() {
    this.router.navigate(['list']);
  }

    get isAdministrator() {
        return this.dataService.hasPermission && this.dataService.isInRole('ADMIN');
    }
    get isAdmin() {
        return this.dataService.hasPermission && (this.dataService.isInRole('APPROV') || this.dataService.isInRole('ADMIN'));
    }
    get statusDisabled() {
        return (!this.dataService.hasPermission || this.origStatus == 'F' || this.origStatus == 'C') ? 'disabled' : null;
    }
    get closeDisabled() {
        return (!this.dataService.hasPermission) ? 'disabled' : null;
    }
    get cancelDisabled() {
        return (!this.dataService.hasPermission) ? 'disabled' : null;
    }
    get openDisabled() {
        return (!this.dataService.hasPermission || (this.origStatus != 'N' && this.origStatus != 'F')) ? 'disabled' : null;
    }
    get approvDisabled() {
        return (!this.dataService.hasPermission || (this.origStatus != 'N' && this.origStatus != 'F') || !this.isAdmin) ? 'disabled' : null;
    }
    get assgnDisabled() {
        return !this.canAssign ? 'disabled' : null;
    }
    get canAssign() {
        return !this.printing && this.dataService.hasPermission && (this.origStatus != 'C') && this.isAdmin;
    }
    get canChangeType() {
        return !this.printing && this.dataService.hasPermission && (this.origStatus != 'F' && this.origStatus != 'C' && this.origStatus != 'P') && this.isAdministrator;
    }

    get programmers() {
        var role = this.ticket.REQTYPE == '04' ? 'PROG' : 'OPER';
        return !this.users ? [] : this.users.filter(x => x.Permissions.some(p => p.P_CODE == role));
    }

    userName(id: any) {
        var ua = this.users.find(x => x.A_ID == id);
        return ua ? ua.USERNAME : id;
    }

    processTicket() {
        this.origStatus = this.ticket.TEMP;
        this.ticket["OpenDate"] = this.datePipe.transform(this.dataService.formatAS400Date(this.ticket.REQDAT, 0), 'mediumDate');
        this.ticket["CloseDate"] = this.datePipe.transform(this.dataService.formatAS400Date(this.ticket.CMPLDT, 0), 'mediumDate');
        this.ticket["AppDate"] = this.datePipe.transform(this.dataService.formatAS400Date(this.ticket.APPRDT, 0), 'mediumDate');
        this.ticket["Phnum"] = this.ticket.PHONE + (this.ticket.PHONEXT != '' ? ` Ext. ${this.ticket.PHONEXT}` : '');
        this.ticket["OpenName"] = this.ticket.REQFNAM !== '' && this.ticket.REQLNAM !== '' ? `${this.ticket.REQFNAM} ${this.ticket.REQLNAM}` : this.ticket.REQBY;
        if (this.ticket.REQTYPE == '04') {
            if (!this.ticket.ReqSystem)
                this.ticket.ReqSystem = [];
            this.ticket["System"] = this.ticket.ReqSystem.map(x => this.convertSystem(x)).join(', ');
        }
        if (!this.ticket.ASGNTO)
            this.ticket.ASGNTO = '';
        if (!this.ticket["AssToName"])
            this.ticket["AssToName"] = this.ticket.ASGNTO;
        var ua = this.users.find(x => x.A_ID == this.ticket.ASGNTO);
        if (ua)
            this.ticket["AssToName"] = ua.USERNAME;
        this.ticket["ObjChanged"] = (this.ticket.Details.length == 0 && this.ticket.ProgrData.length == 0 && this.ticket.ProgrAttachment.length == 0) ? 'N' : 'Y';

        if (this.ticket.ProgList) {
            this.ticket.ProgList.forEach(ap => {
                var p = this.programmers.find(x => x.A_ID == ap.ASGPRG);
                if (p) {
                    this.progSelected.push({ id: p.A_ID, itemName: p.USERNAME });
                    this.ticket["AssToName"] += (', ' + p.USERNAME);
                }
            });
        }

        this.progList = this.programmers.map(x => { return { id: x.A_ID, itemName: x.USERNAME }; });
    }

    doSave() {
        if (this.ticket.TEMP == 'P' && this.ticket.ASGNTO == '') {
            this.errorMessage = 'Assigned To field cannot be empty';
            return;
        }
        if (this.progSelected.length > 0 && this.ticket.ASGNTO == '') {
            this.errorMessage = 'Primary programmer has to be selected';
            return;
        }
        this.ticket.ProgList = this.progSelected.map(x => { return { REQNO: this.ticket.REQNO, ASGPRG: x.id }; });

        this.errorMessage = '';
        this.loading = true;
        this.dataService.saveTicket(this.ticket).subscribe((res: any) => {
          this.loading = false;
          //this.ticket = res;

            this.router.navigate([this.dataService.hasPermission ? 'list' : 'home']);
        },
          (err: any) => {
            this.loading = false;
            this.errorMessage = this.dataService.processError(err);
          });
  }

    viewDoc(key: any) {
        this.errorMessage = '';
        this.loading = true;
        this.dataService.getDocument(this.ticket.REQNO, key).subscribe((result: any) => {
            let objURL = URL.createObjectURL(result);

            this.loading = false;
            //this.fileURL = this.sanitizer.bypassSecurityTrustResourceUrl(objURL + '#toolbar=0');
            var element = document.getElementById('uploadLink') as HTMLAnchorElement;
            element.href = objURL;
            element.download = this.ticket.Attachments.find(x => x.REQLIN == key).REQATT;
            element.click();
            //window.open(objURL, '_blank');
        },
            (err: any) => {
                this.errorMessage = this.dataService.processError(err);
                this.loading = false;
            });
    }

    uploaded(fileItem) {
        this.loading = true;
        let up = { REQNO: this.ticket.REQNO, REQLIN: 0, REQATT: fileItem.file.file.name, REQLNK: fileItem.description };
        this.ticket.Attachments.push(up);
        this.dataService.addUpload(this.ticket).subscribe((res: any) => {
            this.loading = false;
            this.ticket = res;
            this.processTicket();
            this.uploads.push(Object.assign({ key: this.ticket.Attachments[this.ticket.Attachments.length - 1].REQLIN }, fileItem));
        },
            (err: any) => {
                this.loading = false;
                this.errorMessage = this.dataService.processError(err);
            });
    }

    removeUpload(key: any) {
        if (!this.ticket)
            return;

        this.loading = true;
        var ix = this.uploads.findIndex(x => x.key == key);
        this.dataService.removeUpload(this.ticket.REQNO, key).subscribe((res: any) => {
            this.loading = false;
            if (ix >= 0)
                this.uploads.splice(ix, 1);
            ix = this.ticket.Attachments.findIndex(x => x.REQLIN == key);
            if (ix >= 0)
                this.ticket.Attachments.splice(ix, 1);
        },
            (err: any) => {
                this.loading = false;
                this.errorMessage = this.dataService.processError(err);
            });
    }

    doPrint() {
        this.printing = true;
        setTimeout(() => {
            window.print();
            this.printing = false;
        });
    }

    details(evt) {
        //evt.stopPropagation();
        //evt.preventDefault();


        const config = {
            ignoreBackdropClick: true,
            class: 'modal-lg'
        };
        this.bsModalRef = this.modalService.show(ModalDetailsComponent, config);
        this.bsModalRef.content.ticket = this.ticket;
        this.bsModalRef.content.origStatus = this.origStatus;
    }

    convertSystem(sys) {
        switch (sys) {
            case 1: return 'Global Upholstery';
            case 4: return 'Global Express';
            case 14: return 'PC Programming';
            case 99: return 'payroll';
            default: return sys;
        }
    }
}

import { Component } from '@angular/core';
import { ConfirmService } from './confirm.service';

// http://koscielniak.me/post/2016/03/angular2-confirm-dialog-component/

const KEY_ESC = 27;

@Component({
    selector: 'app-confirm',
    template: `
  <div id="confirmationModal" class="modal fade" style="overflow:inherit" role="dialog">
  <div class="modal-dialog">
    <div id="confirmationModalContent" class="modal-content popup">
      <div class="modal-header">
        <h4 class="modal-title">{{title}}</h4>
      </div>
       <div class="modal-body">
        <p>{{message}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn" [ngClass]="{'btn-primary': okText!=='Submit', 'btn-submit': okText==='Submit'}" id="okButtonConfDlg">{{okText}}</button>
        <button [ngStyle]="{'display': cancelText == 'None' ? 'none' : 'inline-block'}" type="button" class="btn btn-default" id="cancelButtonConfDlg">{{cancelText}}</button>
      </div>
    </div>
  </div>
</div>
  `,
    styles: [`
      h4 { font-weight: bold; }
      .popup { position:fixed;top:20vh;left:10%;border:2px solid red;background:#fbc39a;border-radius: 5px;padding:10px 20px }
  `]
})
export class ConfirmComponent {
    public title: string;
    public message: string;
    public okText: string;
    public cancelText: string;

    private _defaults = {
        title: 'Confirm',
        message: 'Confirm the operation?',
        cancelText: 'Cancel',
        okText: 'OK'
    };
    private _confirmElement: any;
    private _cancelButton: any;
    private _okButton: any;

    constructor(confirmService: ConfirmService) {
        // assign a function to the property activate of ConfirmService.
        // After this, calling activate on ConfirmService will cause the function activate
        // from ConfirmComponent to be executed.
        confirmService.activate = this.activate.bind(this);
    }

    private setLabels(message = this._defaults.message, title = this._defaults.title, ok = this._defaults.okText, cancel = this._defaults.cancelText) {
        this.title = title;
        this.message = message;
        this.okText = ok;
        this.cancelText = cancel;
    }

    activate(message = this._defaults.message, title = this._defaults.title, ok = this._defaults.okText, cancel = this._defaults.cancelText) {
        this.setLabels(message, title, ok, cancel);
        //console.log("in activate");

        let promise = new Promise<boolean>(resolve => {
            this.show(resolve);
        });
        return promise;
    }

    private show(resolve: (boolean) => any) {
        document.onkeyup = null;

        let negativeOnClick = (e: any) => resolve(false);
        let positiveOnClick = (e: any) => resolve(true);

        if (!this._confirmElement || !this._cancelButton || !this._okButton) {
            return;
        }
        this._confirmElement.style.opacity = 0;
        this._confirmElement.style.zIndex = 9999;

        this._cancelButton.onclick = ((e: any) => {
            e.preventDefault();
            if (!negativeOnClick(e)) {
                this.hideDialog();
            }
        });

        this._okButton.onclick = ((e: any) => {
            e.preventDefault();
            if (!positiveOnClick(e)) {
                this.hideDialog();
            }
        });

        this._confirmElement.onclick = (e: any) => {
            var el = e.target;
            while (el) {
                if (el.id === 'confirmationModalContent') {
                    this.hideDialog();
                    return negativeOnClick(null);
                }
                el = el.parentElement;
            }
            e.preventDefault();
        };

        document.onkeyup = (e: any) => {
            if (e.which === KEY_ESC) {
                this.hideDialog();
                return negativeOnClick(null);
            }
        };


        this._confirmElement.style.opacity = 1;
        this._confirmElement.style.display = 'block';
    }

    private hideDialog() {
        document.onkeyup = null;
        this._confirmElement.style.opacity = 0;
        window.setTimeout(() => this._confirmElement.style.zIndex = -1, 400);
    }

    ngOnInit(): any {
        this._confirmElement = document.getElementById('confirmationModal');
        this._cancelButton = document.getElementById('cancelButtonConfDlg');
        this._okButton = document.getElementById('okButtonConfDlg');
    }
}

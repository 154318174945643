import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { DataService } from './data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    title = 'Tickets';

    constructor(private dataService: DataService, public router: Router) { }

    ngOnInit() {
        if (window.location.href.indexOf('/adminprog/') > 0)
            return;

        let creds = { username: '**//**', password: '' };
        this.dataService.login(creds).subscribe((res: any) => {
            this.dataService.userInfo = res;
            if (this.dataService.isAuthenticated) {
                if (this.dataService.userInfo.Permissions.some(x => x.S_SHORTP === this.dataService.appID))
                    this.router.navigate(['list']);
            }
        });
    }
}

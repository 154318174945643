import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { DataService } from './data.service';

@Injectable()
export class AuthGuardService implements CanActivate {
    errorMessage: string;

    constructor(public auth: DataService, public router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.auth.isAuthenticated) {
            return this.login().pipe(tap(a => {
                if (!a) {
                    let absoluteUrl = window.location.href;
                    let p = absoluteUrl.lastIndexOf(this.router.url);
                    if (p > 0 && this.router.url != '/')
                        absoluteUrl = absoluteUrl.substr(0, p) + state.url;
                    window.location.href = this.auth.loginURL + '?redirect=' + absoluteUrl + '&target=' + this.auth.appID;
                }
                else {
                    if (!this.auth.userInfo.Permissions.some(x => x.S_SHORTP === this.auth.appID))
                        this.router.navigate(['nauth']);
                }
            }));
            //this.router.navigate(['login']);
            //return false;
        }
        return of(true);
    }

    login() {
        //let creds = { username: 'AMYR@SCI', password: 'AR123' };
        let creds = { username: '**//**', password: '' };
        return this.auth.login(creds).pipe(map((res: any) => {
            this.auth.userInfo = res;
            return this.auth.isAuthenticated;
        },
            (err: any) => {
                this.errorMessage = this.auth.processError(err);
                return of(false);
            }));
    }
}

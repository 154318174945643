import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

import { DataService } from '../data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
    sub: any;
  loading = false;
  errorMessage: string;
  fname: string;
  lname: string;
  email: string;
  phone: string;
  phonext: string;
  company: string;
    priority: string;
  address: string;
  unit: string;
  location: string;
  descr: string;
  reason: string;
  type: string;
  uploads: any[];
    docBlob: any;
    ticket: any;
    submitted = false;
    phase: number;
    settingsSess: any;
    sysList: any[] = [];
    sysSelected: any[];

  constructor(private dataService: DataService, private datePipe: DatePipe, public router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.type = '';
      this.uploads = [];
      this.ticket = null;
      this.loading = false;
      this.phase = 1;
      this.reason = '';
      this.company = '';
      this.priority = '';

      this.settingsSess = {
          badgeShowLimit: 1, enableCheckAll: false, selectAllText: 'All', unSelectAllText: 'Unselect All', text: '--SELECT--'
      };

      this.sysSelected = [];
      this.sysList = [{ id: 1, itemName: 'Global Upholstery' }, { id: 4, itemName: 'Global Express' }, { id: 14, itemName: 'PC Programming' }, { id: 99, itemName: 'Payroll' }];

      this.sub = this.route.params.subscribe(params => {
          if (!params['ctrl'])
              return;

          let reqno = params['ctrl'];
          this.dataService.getTicket(reqno).subscribe((r: any) => {
              this.ticket = r;
              this.fname = r.REQFNAM; this.lname = r.REQLNAM; this.email = r.EMAIL; this.phone = r.PHONE; this.phonext = r.PHONEXT; this.company = r.REQCOMP;
              this.address = r.ADDRESS; this.unit = r.UNIT; this.location = r.LOCATION; this.reason = r.REASON; this.type = r.REQTYPE; this.descr = r.Description;
              this.sysSelected = [];
              if (r.ReqSystem) {
                  r.ReqSystem.forEach(x => {
                      var s = this.sysList.find(a => a.id == x);
                      if (s)
                          this.sysSelected.push(s);
                  });
              }
              for (var att of r.Attachments)
                  this.uploads.push({ key: att.REQLIN, file: { isSuccess: true, file: { name: att.REQATT } }, description: att.REQLNK, selected: false, db: true });
          },
              (err: any) => {
                  this.errorMessage = this.dataService.processError(err);
              });
      });
  }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

  doSave(f: NgForm) {
    if (f.invalid) {
      this.errorMessage = 'Not all required fields are populated';
      return;
    }

    this.ticket = {
        REQNO: 0, REQFNAM: this.fname, REQLNAM: this.lname, EMAIL: this.email, PHONE: this.phone, PHONEXT: this.phonext, REQCOMP: this.company, PRIRTY: this.priority, ASGNTO: '',
        ADDRESS: this.address, UNIT: this.unit, LOCATION: this.location, Description: this.descr, REASON: this.reason, ReqSystem: this.sysSelected.map(x => x.id), REQTYPE: this.type, Attachments: []
      };

      this.ticket.Attachments = this.uploads.filter(x => x.file.isSuccess).map(up => { return { REQNO: this.ticket.REQNO, REQLIN: 0, REQATT: up.file.file.name, REQLNK: up.description }; });

    this.errorMessage = '';
    this.loading = true;
    this.dataService.saveTicket(this.ticket).subscribe((res: any) => {
      this.loading = false;
      this.ticket = res;

        this.submitted = true;
      //this.router.navigate(['list']);
        //this.router.navigate(['admin', (this.ticket.REQNO * 3 + 7).toString()]);
    },
      (err: any) => {
        this.loading = false;
        this.errorMessage = this.dataService.processError(err);
      });
    }

    doReview() {
        if (this.ticket && this.ticket.REQNO > 0)
            this.router.navigate(['admin', (this.ticket.REQNO * 3 + 7).toString()]);
    }

  uploaded(fileItem) {
      //this.loading = true;
      //let up = { REQNO: this.ticket.REQNO, REQLIN: 0, REQATT: fileItem.file.file.name, REQLNK: fileItem.description };
      //this.ticket.Attachments.push(up);
      let len = this.uploads.length;
      this.uploads.push(Object.assign({ key: len+1 }, fileItem));
      //this.dataService.addUpload(this.ticket).subscribe((res: any) => {
      //    this.loading = false;
      //    this.ticket = res;
      //    this.uploads.push(Object.assign({ key: this.ticket.Attachments[this.ticket.Attachments.length - 1].REQLIN }, fileItem));
      //},
      //    (err: any) => {
      //        this.loading = false;
      //        this.errorMessage = this.dataService.processError(err);
      //    });
  }

    removeUpload(key: any) {
        //this.loading = true;
        var ix = this.uploads.findIndex(x => x.key == key);
        if (ix >= 0)
            this.uploads.splice(ix, 1);
        for (var i = 0; i < this.uploads.length; i++)
            this.uploads[i].key = i + 1;
        //this.dataService.removeUpload(this.ticket.REQNO, key).subscribe((res: any) => {
        //  this.loading = false;
        //    ix = this.ticket.Attachments.findIndex(x => x.REQLIN == key);
        //    if (ix >= 0)
        //        this.ticket.Attachments.splice(ix, 1);
        //},
        //  (err: any) => {
        //    this.loading = false;
        //    this.errorMessage = this.dataService.processError(err);
        //  });
  }

  viewDoc(key: any) {
    this.errorMessage = '';
    //this.loading = true;
      this.dataService.getDocument(this.ticket.REQNO, key).subscribe((result: any) => {
      this.docBlob = result;
      let objURL = URL.createObjectURL(result);

      //this.loading = false;
      //this.fileURL = this.sanitizer.bypassSecurityTrustResourceUrl(objURL + '#toolbar=0');
          var element = document.getElementById('uploadLink') as HTMLAnchorElement;
          element.href = objURL;
          element.download = this.ticket.Attachments.find(x => x.REQLIN == key).REQATT;
          element.click();
      //window.open(objURL, '_blank');
    },
      (err: any) => {
        this.errorMessage = this.dataService.processError(err);
        //this.loading = false;
      });
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AgGridModule } from '@ag-grid-community/angular';
import { ModalModule, BsDatepickerModule, PopoverModule, ButtonsModule, TooltipModule, BsDropdownModule } from 'ngx-bootstrap';
import { FileUploadModule } from 'ng2-file-upload';
import { MyDatePickerModule } from 'mydatepicker';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

import { AppRoutingModule } from './app-routing.module';
import { DataService } from './data.service';
import { AuthGuardService } from './auth-guard.service';
import { ConfirmService } from './confirmation-dialog/confirm.service';
import { CustomDateComponent } from './custom-date.component';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ListComponent } from './list/list.component';
import { AdminComponent } from './admin/admin.component';
import { ModalDetailsComponent } from './admin/modal-details.component';
import { NotAuthorizedComponent } from './not-authorized.component';
import { DocUploadComponent } from './doc-upload/doc-upload.component';
import { ConfirmComponent } from './confirmation-dialog/confirm.component';

@NgModule({
  declarations: [
      AppComponent, NotAuthorizedComponent, DocUploadComponent, ConfirmComponent, CustomDateComponent, ModalDetailsComponent,
    HomeComponent,
    ListComponent,
    AdminComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule, ReactiveFormsModule, HttpClientModule, FormsModule,
    ModalModule.forRoot(), BsDatepickerModule.forRoot(), PopoverModule.forRoot(), ButtonsModule.forRoot(), TooltipModule.forRoot(), BsDropdownModule.forRoot(),
      AgGridModule.withComponents([CustomDateComponent]),
      FileUploadModule, MyDatePickerModule, AngularMultiSelectModule,
  ],
    providers: [DataService, DatePipe, AuthGuardService, ConfirmService],
    entryComponents: [ModalDetailsComponent],
    bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit, Input, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { DataService } from '../data.service';
import { AdminComponent } from './admin.component';

@Component({
    selector: 'app-modal-email-carrier',
    templateUrl: './modal-details.component.html',
    styles: [`.field_name {margin-right:8px; vertical-align: top;}`]
})
export class ModalDetailsComponent implements OnInit {
    errorMessage: string;
    loading: boolean = false;
    @Input() origStatus: string;
    @Input() ticket: any = {};
    uploadsProg: any[] = [];
    comPos: number = 0;

    constructor(public router: Router, private dataService: DataService, @Inject(AdminComponent) private admin: AdminComponent) { }

    get statusDisabled() {
        return (this.origStatus == 'C');
    }

    ngOnInit() {
        this.comPos = this.ticket.ProgrData.length;
        for (var att of this.ticket.ProgrAttachment)
            this.uploadsProg.push({ key: att.REQLIN, file: { isSuccess: true, file: { name: att.REQATT } }, description: att.REQLNK, selected: false, db: true });
    }

    add() {
        this.ticket.Details.push({ OBJNAM: '', OBJTXT: '' });
    }

    addProgComment() {
        this.ticket.ProgrData.push({ UPDTBY: this.dataService.userInfo.A_ID, REQCMT: '' });
    }

    onChange(val: string, maxlen: number) {
        if (val.length > maxlen)
            this.errorMessage = `Field length cannot exceed ${maxlen} characters`;
        else
            this.errorMessage = '';
    }

    send() {
        this.errorMessage = '';
        for (var i = this.ticket.Details.length - 1; i >= 0; i--) {
            if (this.ticket.Details[i].OBJNAM.length > 10) {
                this.errorMessage = `Object Name length cannot exceed 10 characters`;
                return;
            }
            if (this.ticket.Details[i].OBJTXT.length > 256) {
                this.errorMessage = `Description length cannot exceed 256 characters`;
                return;
            }
            if (this.ticket.Details[i].OBJNAM.trim().length == 0)
                this.ticket.Details.splice(i, 1);
        }
        if (this.ticket.Details.length == 0) 
            return;

        //for (var i = 0; i < this.ticket.Details.length - 1; i++)
        //    this.ticket.Details["LINENO"] = i;

        this.loading = true;

        this.dataService.saveTicketDetails(this.ticket).subscribe((res: any) => {
            this.loading = false;
            this.ticket.Details = res.Details;
            //this.bsModalRef.hide();
        },
            (err: any) => {
                this.loading = false;
                this.errorMessage = this.dataService.processError(err);
            });
    }

    viewDoc(key: any) {
        this.errorMessage = '';
        this.loading = true;
        this.dataService.getDocument(this.ticket.REQNO, key, true).subscribe((result: any) => {
            let objURL = URL.createObjectURL(result);

            this.loading = false;
            //this.fileURL = this.sanitizer.bypassSecurityTrustResourceUrl(objURL + '#toolbar=0');
            var element = document.getElementById('uploadLink') as HTMLAnchorElement;
            element.href = objURL;
            element.download = this.ticket.ProgrAttachment.find(x => x.REQLIN == key).REQATT;
            element.click();
            //window.open(objURL, '_blank');
        },
            (err: any) => {
                this.errorMessage = this.dataService.processError(err);
                this.loading = false;
            });
    }

    uploaded(fileItem) {
        this.loading = true;
        let up = { REQNO: this.ticket.REQNO, REQLIN: 0, REQATT: fileItem.file.file.name, REQLNK: fileItem.description };
        this.dataService.addProgUpload(up).subscribe((res: any) => {
            this.loading = false;
            this.ticket.ProgrAttachment.push(res);
            this.uploadsProg.push(Object.assign({ key: res.REQLIN }, fileItem));
            this.ticket["ObjChanged"] = 'Y';
        },
            (err: any) => {
                this.loading = false;
                this.errorMessage = this.dataService.processError(err);
            });
    }

    removeUpload(key: any) {
        if (!this.ticket)
            return;

        this.loading = true;
        var ix = this.uploadsProg.findIndex(x => x.key == key);
        this.dataService.removeUpload(this.ticket.REQNO, key, true).subscribe((res: any) => {
            this.loading = false;
            if (ix >= 0)
                this.uploadsProg.splice(ix, 1);
            ix = this.ticket.ProgrAttachment.findIndex(x => x.REQLIN == key);
            if (ix >= 0)
                this.ticket.ProgrAttachment.splice(ix, 1);
            this.ticket["ObjChanged"] = (this.ticket.Details.length == 0 && this.ticket.ProgrData.length == 0 && this.ticket.ProgrAttachment.length == 0) ? 'N' : 'Y';
        },
            (err: any) => {
                this.loading = false;
                this.errorMessage = this.dataService.processError(err);
            });
    }
}



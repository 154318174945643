import { Component, ElementRef, ViewChild } from '@angular/core';
/*import { IMyDpOptions } from 'mydatepicker';

@Component({
    selector: 'app-loading-overlay',
    template: `
    <div class="ag-custom-component-popup ag-input-wrapper custom-date-filter2">
        <my-date-picker name="mydate" [options]="myDatePickerOptions" [(ngModel)]="datePick"></my-date-picker>
    </div>
  `,
    styles: [
        `
      .custom-date-filter a {
        position: absolute;
        right: 20px;
        color: rgba(0, 0, 0, 0.54);
        cursor: pointer;
      }

      .custom-date-filter:after {
        position: absolute;
        content: '\f073';
        display: block;
        font-weight: 400;
        font-family: 'Font Awesome 5 Free';
        right: 5px;
        pointer-events: none;
        color: rgba(0, 0, 0, 0.54);
      }
    `,
    ],
})
export class CustomDateComponent {
    //@ViewChild('flatpickrEl', { read: ElementRef, static: false }) flatpickrEl: ElementRef;
    //@ViewChild('eInput', { read: ElementRef, static: false }) eInput: ElementRef;
    private date: Date;
    private datePick: any;
    private params: any;
    //private picker: any;
    public myDatePickerOptions: IMyDpOptions = { dateFormat: 'yyyy-mm-dd', disableWeekends: true, showTodayBtn: false, firstDayOfWeek: 'su', markCurrentDay: true, allowDeselectDate: true, sunHighlight: false, width: '140px' };

    agInit(params: any): void {
        this.params = params;
        this.datePick = { jsdate: new Date() };
    }

    //ngAfterViewInit(): void {
    //    this.picker = flatpickr(this.flatpickrEl.nativeElement, {
    //        onChange: this.onDateChanged.bind(this),
    //        wrap: true,
    //    });

    //    this.picker.calendarContainer.classList.add('ag-custom-component-popup');
    //}

    ngOnDestroy() {
        console.log(`Destroying DateComponent`);
    }

    onDateChanged(selectedDates) {
        this.date = selectedDates[0] || null;
        this.params.onDateChanged();
    }

    getDate(): Date {
        return this.datePick.jsdate;
    }

    setDate(date: Date): void {
        this.date = date || null;
        this.datePick.jsdate = date;
    }

    setInputPlaceholder(placeholder: string): void {
        //this.eInput.nativeElement.setAttribute('placeholder', placeholder);
    }
}*/
import flatpickr from "flatpickr";

@Component({
    selector: 'app-loading-overlay',
    template: `
    <div
      #flatpickrEl
      class="ag-input-wrapper custom-date-filter"
      role="presentation"
    >
      <input type="text" #eInput data-input style="width: 100%;" />
      <a class="input-button" title="clear" data-clear>
        <i class="fa fa-times"></i>
      </a>
    </div>
  `,
    styles: [
        `
      .custom-date-filter a {
        position: absolute;
        right: 20px;
        color: rgba(0, 0, 0, 0.54);
        cursor: pointer;
      }

      .custom-date-filter:after {
        position: absolute;
        content: '\f073';
        display: block;
        font-weight: 400;
        font-family: 'FontAwesome';
        right: 5px;
        pointer-events: none;
        color: rgba(0, 0, 0, 0.54);
      }
    `,
    ],
})
export class CustomDateComponent {
    @ViewChild('flatpickrEl', { read: ElementRef, static: false }) flatpickrEl: ElementRef;
    @ViewChild('eInput', { read: ElementRef, static: false }) eInput: ElementRef;
    private date: Date;
    private params: any;
    private picker: any;

    agInit(params: any): void {
        this.params = params;
    }

    ngAfterViewInit(): void {
        // outputs `I am span`
        this.picker = flatpickr(this.flatpickrEl.nativeElement, {
            onChange: this.onDateChanged.bind(this),
            wrap: true,
        });

        this.picker.calendarContainer.classList.add('ag-custom-component-popup');
    }

    ngOnDestroy() {
        console.log(`Destroying DateComponent`);
    }

    onDateChanged(selectedDates) {
        this.date = selectedDates[0] || null;
        this.params.onDateChanged();
    }

    getDate(): Date {
        return this.date;
    }

    setDate(date: Date): void {
        this.date = date || null;
        this.picker.setDate(date);
    }

    setInputPlaceholder(placeholder: string): void {
        this.eInput.nativeElement.setAttribute('placeholder', placeholder);
    }
}

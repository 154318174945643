import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService as AuthGuard } from './auth-guard.service';
import { HomeComponent } from './home/home.component';
import { ListComponent } from './list/list.component';
import { AdminComponent } from './admin/admin.component';
import { NotAuthorizedComponent } from './not-authorized.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
    { path: 'home/:ctrl', component: HomeComponent },
    { path: 'list', component: ListComponent, canActivate: [AuthGuard] },
  //{ path: 'admin', component: AdminComponent, canActivate: [AuthGuard] },
  { path: 'admin/:ctrl', component: AdminComponent },
    { path: 'adminprog/:ctrl', component: AdminComponent, canActivate: [AuthGuard] },
  { path: 'nauth', component: NotAuthorizedComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
